import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { map } from 'leaflet';
import { Observable, of, Subscription } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { Queue } from 'src/app/commons/queue.class';
import { Utilities } from 'src/app/commons/utilities.class';
import { Boat } from 'src/app/models/boat/Boat';
import { Image } from 'src/app/models/image/Image';
import { InterventType } from 'src/app/models/interventType/InterventType';
import { Project } from 'src/app/models/project/Project';
import { Section } from 'src/app/models/section/Section';
import { Task } from 'src/app/models/task/Task';
import { TaskHistory } from 'src/app/models/task/TaskHistory';
import { AppState } from 'src/app/states/AppState';
import { addImage, syncImage } from 'src/app/states/images/images.actions';
import { selectImage } from 'src/app/states/images/ImagesState';
import { selectAllProjects, selectProject } from 'src/app/states/projects/ProjectsState';
import { syncSections, upsertSection } from 'src/app/states/sections/sections.actions';
// eslint-disable-next-line max-len
import { imagesToSync, sectionsToSync, startSyncImages, startSyncSections, stopSyncImages, stopSyncSections } from 'src/app/states/syncing/syncing.actions';
import { SyncingState } from 'src/app/states/syncing/SyncingState';
import { syncTasks } from 'src/app/states/tasks/tasks.actions';
import { DocumentService } from './document/document.service';
import { ProjectService } from './project/project.service';
import { TaskService } from './task/task.service';
import { UserService } from './user/user.service';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  syncingStateSubscription: Subscription;
  imageQueue: Queue<{ id: string; size: string }> = new Queue<{ id: string; size: string }>();
  projectSectionsQueue: Queue<string> = new Queue<string>();
  sectionQueue: Queue<Section> = new Queue<Section>();
  projectTasksQueue: Queue<string> = new Queue<string>();

  constructor(
    private store: Store<AppState>,
    private documentService: DocumentService,
    private userService: UserService,
    private projectService: ProjectService,
    private taskService: TaskService
  ) {
    this.syncingStateSubscription = this.store.select('syncing').subscribe(syncingState => {
      this.syncImages(syncingState);
      // this.syncProjectSections();
      this.syncSections(syncingState);
    });

  }

  getUser(): Observable<any> {
    return new Observable<any>((observer) => {
      try {
        this.userService.getUser().then((call$) => {
          call$.subscribe(async (retrievedUser) => {
            const user = retrievedUser.attributes;
            observer.next(user);
            observer.complete();
          });
        });
      } catch (error) {
        observer.complete();
      }
    });

  }

  getInterventTypes(): Observable<InterventType[]> {
    return new Observable<Section[]>((observer) => {
      try {
        this.taskService.getInterventTypes().then((call$) => {
          call$.subscribe(async (retrievedInterventTypes) => {
            const interventTypes = [];
            await Utilities.asyncForEach(retrievedInterventTypes, async (retrievedInterventType) => {
              const interventType = await this.cleanInterventType(retrievedInterventType);
              interventTypes.push(interventType);
            });
            observer.next(interventTypes);
            observer.complete();
          }, (error) => {
            if (403 === error.status) {
              observer.error(error);
            }
            else {
              observer.complete();
            }
          });
        });
      } catch (error) {
        observer.complete();
      }
    });

  }

  /**
   * Call the get projects functionality
   *
   * @returns
   * An observable
   */
  getProjects(): Observable<Project[]> {
    return new Observable<Project[]>((observer) => {
      try {
        // const filters: { name: string; value: any }[] = [{ name: 'active', value: true }];
        const filters: { name: string; value: any }[] = [{ name: 'status', value: 'in_site' }];
        const includes = [];
        const params = { filters, includes };

        const projects = [];
        this.projectService.getProjects(params).then((call$) => {
          call$.subscribe(async (retrievedProjects) => {
            for (let index = 0; index < retrievedProjects.length; index++) {
              const retrievedProject = retrievedProjects[index];
              const project = await this.cleanProject(retrievedProject);
              if (project.boatImageId && (project.boatImageId !== null)) {
                this.imageQueue.enqueue({ id: project.boatImageId, size: null });
              }
              projects.push(project);
            }
            // await Utilities.asyncForEach(retrievedProjects, async (retrievedProject: any) => {
            //   const project = await this.cleanProject(retrievedProject);
            //   // if (((project.status) && (project.status !== 'draft') && (project.status !== 'closed')) && (+project.sections > 0)) {
            //   if (((project.status) && (project.status !== 'draft') && (project.status !== 'closed'))) {
            //     if (project.boatImageId && (project.boatImageId !== null)) {
            //       this.imageQueue.enqueue({ id: project.boatImageId, size: null });
            //     }
            //     if (project.coverImageUrl && (project.coverImageUrl !== null)) {
            //       // this.imageQueue.enqueue({ id: project.coverImageUrl, size: null });
            //     }

            //     projects.push(project);
            //     // this.projectSectionsQueue.enqueue(project.id);
            //   }
            // });
            await Utilities.waitFor(20);
            this.store.dispatch(imagesToSync());
            // console.log('projects', projects);
            observer.next(projects);
            observer.complete();
          }, (error) => {
            if (403 === error.status) {
              observer.error(error);
            }
            else {
              observer.complete();
            }
          });
        });
      } catch (error) {
        observer.complete();
      }
    });
  }


  /**
   * Call the get project sections functionality
   *
   * @returns
   * An observable
   */
  getAllProjectsSections(): Observable<Section[]> {
    return new Observable<Section[]>((observer) => {
      try {
        this.store.select(selectAllProjects).pipe(take(1)).subscribe(async (projects) => {
          await Utilities.asyncForEach(projects, async (project) => {
            // this.store.dispatch(syncSections({ projectId: project.boatId }));
            if (project && project.boatId) {
              const projectId = '' + project.id;
              const boatId = '' + project.boatId;
              this.projectService.getProjectSections(boatId).then((call$) => {
                call$.subscribe(async (retrievedSections) => {
                  const sections = [];
                  await Utilities.asyncForEach(retrievedSections, async (retrievedSection) => {
                    const section = await this.cleanSection(retrievedSection, projectId);
                    if (section.imageId) {
                      this.imageQueue.enqueue({ id: section.imageId, size: null });
                    }
                    this.sectionQueue.enqueue(section);
                    sections.push(section);
                  });
                  this.store.dispatch(imagesToSync());
                  this.store.dispatch(sectionsToSync());
                });
              });
            }
          });
          observer.complete();
        });
      } catch (error) {
        observer.complete();
      }
    });
  }

  /**
   * Call the get project sections functionality
   *
   * @returns
   * An observable
   */
  getProjectSections(projectId: string): Observable<Section[]> {
    return new Observable<Section[]>((observer) => {
      try {
        this.store.select(selectProject(projectId)).pipe(take(1)).subscribe((project) => {
          if (project && project.boatId) {
            const boatId = '' + project.boatId;
            this.projectService.getProjectSections(boatId).then((call$) => {
              call$.subscribe(async (retrievedSections) => {
                const sections = [];
                await Utilities.asyncForEach(retrievedSections, async (retrievedSection) => {
                  const section = await this.cleanSection(retrievedSection, projectId);
                  if (section.imageId) {
                    this.imageQueue.enqueue({ id: section.imageId, size: null });
                  }
                  this.sectionQueue.enqueue(section);
                  sections.push(section);
                });
                this.store.dispatch(imagesToSync());
                this.store.dispatch(sectionsToSync());
                observer.next(sections);
                observer.complete();
              });
            });
          }
          else {
            this.projectSectionsQueue.enqueue(projectId);
            observer.complete();
          }
        });
      } catch (error) {
        observer.complete();
      }
    });
  }

  async syncImage(imageId: string, imageSize: string) {
    return new Promise((resolve, reject) => {
      try {
        this.store.select(selectImage(imageId)).pipe(
          take(1)
        ).subscribe(async image => {
          if (!image) {
            this.store.dispatch(syncImage({ imageId, imageSize }));
            await Utilities.waitFor(20);
            resolve(true);
          }
          else {
            resolve(true);
          }
        });
      } catch (error) {
        resolve(false);
      }
    });
  }

  async syncImages(syncingState: SyncingState) {
    if (syncingState && syncingState.imagesToSync && !syncingState.imagesSyncing) {
      this.store.dispatch(startSyncImages());
      while (!this.imageQueue.isEmpty()) {
        const image = this.imageQueue.dequeue();
        const imageId = image.id;
        const imageSize = image.size;
        await this.syncImage(imageId, imageSize);
      }
      this.store.dispatch(stopSyncImages());
    }
  }

  async syncSection(section: Section) {
    return new Promise((resolve, reject) => {
      try {
        if (section) {
          this.store.dispatch(upsertSection({ section }));
          resolve(true);
        }
        else {
          resolve(true);
        }
      } catch (error) {
        resolve(false);
      }
    });
  }

  async syncSections(syncingState: SyncingState) {
    if (syncingState && syncingState.sectionsToSync && !syncingState.sectionsSyncing) {
      this.store.dispatch(startSyncSections());
      while (!this.sectionQueue.isEmpty()) {
        const section = this.sectionQueue.dequeue();
        await this.syncSection(section);
      }
      this.store.dispatch(stopSyncSections());
    }
  }

  async syncProjectSections(projectId: string = null) {
    if (projectId) {
      this.store.dispatch(syncSections({ projectId }));
    }
    else {
      while (!this.projectSectionsQueue.isEmpty()) {
        const queuedProjectId = this.projectSectionsQueue.dequeue();
        this.store.dispatch(syncSections({ projectId: queuedProjectId }));
        await Utilities.waitFor(20);
      }
    }
  }

  async syncProjectTasks() {
    this.store.dispatch(syncTasks());
    // while (!this.projectTasksQueue.isEmpty()) {
    //   const projectId = this.projectSectionsQueue.dequeue();
    //   this.store.dispatch(syncSections({ projectId }));
    //   await Utilities.waitFor(20);
    // }
  }

  /**
   * Call the get image functionality
   *
   * @returns
   * An observable
   */
  getImage(imageId: string, size: string = null): Observable<Image> {
    return new Observable<Image>((observer) => {
      try {
        this.documentService.getImage(imageId, size).then((call$) => {
          call$.subscribe(async (retrievedImage) => {
            const image: Image = await this.cleanImage(retrievedImage);
            observer.next(image);
            observer.complete();
          });
        });
      } catch (error) {
        observer.complete();
      }
    });
  }

  /**
   * Call the get image functionality
   *
   * @returns
   * An observable
   */
  addTaskImage(taskId: string, taskHistoryId: string, image: any): Observable<Image> {
    return new Observable<Image>((observer) => {
      try {
        const imageData = {
          title: 'task_' + taskId + '_' + image.attributes.doc_type,
          file: image.attributes.base64,
          filename: 'task_' + taskId + '_' + image.attributes.doc_type + '.jpeg',
          type: image.attributes.doc_type,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          entity_type: 'history',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          entity_id: taskHistoryId
        };
        // console.log(imageData);
        // observer.next(image);
        // observer.complete();


        this.documentService.addImage(imageData).then((call$) => {
          call$.subscribe(async (retrievedImage) => {
            const sendedImage: Image = await this.cleanImage(retrievedImage);
            observer.next(sendedImage);
            observer.complete();
          });
        });
      } catch (error) {
        observer.complete();
      }
    });
  }

  getTasks(): Observable<Task[]> {
    return new Observable<Task[]>((observer) => {
      try {
        this.taskService.getTasks().then((call$) => {
          call$.subscribe(async (retrievedTasks) => {
            const tasks = [];
            await Utilities.asyncForEach(retrievedTasks, async (retrievedTask: any) => {
              const taskHistory = await this.getTaskHistory(retrievedTask.id, true);
              retrievedTask.history = taskHistory;

              const task = await this.cleanTask(retrievedTask, '', '', '');
              if (task.lastHistory.photos && task.lastHistory.photos.data && task.lastHistory.photos.data.detailed_images) {
                Utilities.asyncForEach(task.lastHistory.photos.data.detailed_images, detailedImage => {
                  this.imageQueue.enqueue({ id: detailedImage.id, size: 'thumb' });
                });
              }
              if (task.lastHistory.photos && task.lastHistory.photos.data && task.lastHistory.photos.data.additional_images) {
                Utilities.asyncForEach(task.lastHistory.photos.data.additional_images, additionalImage => {
                  this.imageQueue.enqueue({ id: additionalImage.id, size: 'thumb' });
                });
              }
              tasks.push(task);
            });
            this.store.dispatch(imagesToSync());
            observer.next(tasks);
            observer.complete();
          }, (error) => {
            if (403 === error.status) {
              observer.error(error);
            }
            else {
              observer.complete();
            }
          });
        });
      } catch (error) {
        observer.complete();
      }
    });
  }

  getProjectTasks(projectId: string): Observable<Task[]> {
    return new Observable<Task[]>((observer) => {
      try {
        this.taskService.getProjectTasks(projectId).then((call$) => {
          call$.subscribe(async (retrievedTasks) => {
            const tasks = [];
            for (let index = 0; index < retrievedTasks.length; index++) {
              const retrievedTask = retrievedTasks[index];
              const taskHistory = await this.getTaskHistory(retrievedTask.id, true);
              retrievedTask.history = taskHistory;
              const task = await this.cleanTask(retrievedTask, '', '', '');
              if (task.lastHistory.photos && task.lastHistory.photos.data?.detailed_images) {
                Utilities.asyncForEach(task.lastHistory.photos.data.detailed_images, detailedImage => {
                  this.imageQueue.enqueue({ id: detailedImage.id, size: 'thumb' });
                });
              }
              if (task.lastHistory.photos && task.lastHistory.photos.data?.additional_images) {
                Utilities.asyncForEach(task.lastHistory.photos.data.additional_images, additionalImage => {
                  this.imageQueue.enqueue({ id: additionalImage.id, size: 'thumb' });
                });
              }
              tasks.push(task);
              // console.log('task');
            }
            // await Utilities.asyncForEach(retrievedTasks, async (retrievedTask: any) => {
            //   // console.log('retrievedTask', retrievedTask);
            //   const taskHistory = await this.getTaskHistory(retrievedTask.id);
            //   retrievedTask.history = taskHistory;
            //   const task = await this.cleanTask(retrievedTask, '', '', '');
            //   if (task.lastHistory.photos && task.lastHistory.photos.data?.detailed_images) {
            //     Utilities.asyncForEach(task.lastHistory.photos.data.detailed_images, detailedImage => {
            //       this.imageQueue.enqueue({ id: detailedImage.id, size: 'thumb' });
            //     });
            //   }
            //   if (task.lastHistory.photos && task.lastHistory.photos.data?.additional_images) {
            //     Utilities.asyncForEach(task.lastHistory.photos.data.additional_images, additionalImage => {
            //       this.imageQueue.enqueue({ id: additionalImage.id, size: 'thumb' });
            //     });
            //   }
            //   tasks.push(task);
            // });
            this.store.dispatch(imagesToSync());
            observer.next(tasks);
            observer.complete();
          });
        });
      } catch (error) {
        observer.complete();
      }
    });
  }

  addTask(data: Task): Observable<Task> {
    return new Observable<Task>((observer) => {
      try {
        this.prepareTaskAttributes(data).then((taskAttributes) => {
          this.taskService.addTask(taskAttributes).then((call$) => {
            call$.subscribe(async (addedTask) => {
              // console.log('addedTask', addedTask);
              const taskHistory = await this.getTaskHistory(addedTask.id);
              addedTask.history = taskHistory;

              const task = await this.cleanTask(addedTask, '', '', '');

              // console.log('task.id', task.id);
              // console.log('task.lastHistory', task.lastHistory);
              if ((data.lastHistory.comments) && (data.lastHistory.comments[0]) && (data.lastHistory.comments[0].body)) {
                await this.addCommentToTaskHistory(task.lastHistory.id, data.lastHistory.comments[0].body);
              }

              if ((data.lastHistory.photos) && (data.lastHistory.photos.data) && (data.lastHistory.photos.data.additional_images)) {
                Utilities.asyncForEach(
                  data.lastHistory.photos.data.additional_images, (image: Image) => {
                    this.addTaskImage(task.id, task.lastHistory.id, image).pipe(take(1)).subscribe((addedImage) => {
                      // console.log('addedImage', addedImage);
                    });
                  }
                );
              }

              if ((data.lastHistory.photos) && (data.lastHistory.photos.data) && (data.lastHistory.photos.data.detailed_images)) {
                Utilities.asyncForEach(
                  data.lastHistory.photos.data.detailed_images, (image: Image) => {
                    this.addTaskImage(task.id, task.lastHistory.id, image).pipe(take(1)).subscribe((addedImage) => {
                      // console.log('addedImage', addedImage);
                    });
                  }
                );
              }

              observer.next(task);
              observer.complete();
            });
          });
        });
      } catch (error) {
        observer.complete();
      }
    });
  }

  updateTask(taskId: string, data: any): Observable<Task> {
    return new Observable<Task>((observer) => {
      try {
        this.prepareTaskAttributes(data).then((taskAttributes) => {
          this.taskService.updateTask(taskId, taskAttributes).then((call$) => {
            call$.subscribe(async (updatedTask) => {
              // console.log('updatedTask', updatedTask);
              const taskHistory = await this.getTaskHistory(updatedTask.id);
              updatedTask.history = taskHistory;

              const task = await this.cleanTask(updatedTask, '', '', '');
              if (task.lastHistory.photos.data.detailed_images) {
                Utilities.asyncForEach(task.lastHistory.photos.data.detailed_images, detailedImage => {
                  this.imageQueue.enqueue({ id: detailedImage.id, size: 'thumb' });
                });
              }
              if (task.lastHistory.photos.data.additional_images) {
                Utilities.asyncForEach(task.lastHistory.photos.data.additional_images, additionalImage => {
                  this.imageQueue.enqueue({ id: additionalImage.id, size: 'thumb' });
                });
              }
              this.store.dispatch(imagesToSync());
              observer.next(task);
              observer.complete();
            });
          });
        });
      } catch (error) {
        observer.complete();
      }
    });
  }

  deleteTask(taskId: string): Observable<boolean> {
    return new Observable<boolean>((observer) => {
      try {
        this.taskService.deleteTask(taskId).then((call$) => {
          call$.subscribe(async (deleted) => {
            observer.next(deleted);
            observer.complete();
          });
        });
      } catch (error) {
        observer.complete();
      }
    });
  }

  deleteTaskHistoryImage(taskHistoryId: string, imageId: string): Observable<boolean> {
    return new Observable<boolean>((observer) => {
      try {
        this.taskService.deleteHistoryImage(taskHistoryId, imageId).then((call$) => {
          call$.subscribe(async (deleted) => {
            observer.next(deleted);
            observer.complete();
          });
        });
      } catch (error) {
        observer.complete();
      }
    });
  }

  addCommentToTaskHistory(taskHistoryId: string, commentText: string) {
    return new Promise((resolve, reject) => {
      try {
        this.taskService.addCommentToTaskHistory(taskHistoryId, commentText).pipe(
          take(1)
        ).subscribe(async (res) => {
          resolve(res);
        }, (error) => {
          console.log('error', error);
          reject();
        });
      } catch (error) {
        reject(error);
      }
    });

  }

  updateComment(commentId: string, commentText: string) {
    return new Promise((resolve, reject) => {
      try {
        this.taskService.updateComment(commentId, commentText).pipe(
          take(1)
        ).subscribe(async (res) => {
          resolve(res);
        }, (error) => {
          console.log('error', error);
          reject();
        });
      } catch (error) {
        reject(error);
      }
    });
  }

  addDraft(draft: Task): Observable<Task> {
    return new Observable<Task>(observer => {
      if (
        (draft) &&
        (draft.lastHistory) &&
        (draft.lastHistory.photos) &&
        (draft.lastHistory.photos.data)
      ) {
        if (
          (draft.lastHistory.photos.data.additional_images)
        ) {
          draft.lastHistory.photos.data.additional_images.forEach(additionalImage => {
            const image = new Image();
            image.id = additionalImage.id;
            image.base64 = additionalImage.attributes.base64;
            this.store.dispatch(addImage({ image }));
          });
        }
        if (
          (draft.lastHistory.photos.data.detailed_images)
        ) {
          draft.lastHistory.photos.data.detailed_images.forEach(detailedImage => {
            const image = new Image();
            image.id = detailedImage.id;
            image.base64 = detailedImage.attributes.base64;
            this.store.dispatch(addImage({ image }));
          });
        }
      }
      observer.next(draft);
      observer.complete();
    });
  }


  // TODO
  /**
   * Call the load project functionality
   *
   * @param projectId
   * The project ID
   *
   * @returns
   * An observable
   */
  loadProject(projectId: string): Observable<Project> {
    return new Observable<Project>(observer => {
      observer.next();
      observer.complete();
    });
  }

  sendDraft(draftId: string): Observable<void> {
    return new Observable<void>(observer => {
      // TODO
      setTimeout(() => {
        if ('error' === draftId) {
          observer.error({
            message: 'Draft not found'
          });
        }
        observer.next();
        observer.complete();
      }, 2000);
    });
  }

  sendDrafts(draftIds: string[]): Observable<void> {
    return new Observable<void>(observer => {
      // TODO
      setTimeout(() => {
        if (draftIds && (draftIds.length > 0)) {
          observer.error({
            message: 'Drafts not found'
          });
        }
        observer.next();
        observer.complete();
      }, 2000);
    });
  }

  private getTaskHistory(taskId: string, noPhotos: boolean = false) {
    return new Promise<any>(async (resolve, reject) => {
      try {
        this.taskService.getTaskHistory(taskId, noPhotos).then((innerCall$) => {
          innerCall$.subscribe(async (retrievedHistory) => {
            // console.warn('getTaskHistory', retrievedHistory);
            resolve(retrievedHistory);
          });
        });
      } catch (error) {
        reject(error);
      }
    });
  }

  private cleanImage(dirtyImage: any) {
    return new Promise<Image>(async (resolve, reject) => {
      try {
        const image: Image = new Image();
        image.id = '' + dirtyImage.id;
        image.base64 = dirtyImage.attributes.base64;
        resolve(image);
      } catch (error) {
        reject(error);
      }
    });
  }

  private cleanInterventType(dirtyInterventType: any) {
    return new Promise<InterventType>(async (resolve, reject) => {
      try {
        const interventType: InterventType = new InterventType();
        interventType.id = '' + dirtyInterventType.id;
        // interventType.name = dirtyInterventType.attributes.name;
        // eslint-disable-next-line max-len
        interventType.name = '' + dirtyInterventType.attributes.name?.charAt(0).toUpperCase() + dirtyInterventType.attributes.name?.slice(1);
        resolve(interventType);
      } catch (error) {
        reject(error);
      }
    });
  }

  private cleanProject(dirtyProject: any) {
    return new Promise<Project>(async (resolve, reject) => {
      try {
        const project: Project = new Project();
        project.id = '' + dirtyProject.id;
        // project.name = dirtyProject.attributes.name;
        project.name = dirtyProject.attributes.boat_name;
        project.status = dirtyProject.attributes.status;
        // project.description = dirtyProject.attributes.location?.name;
        project.boatId = dirtyProject.attributes.boat_id;
        project.coverImageUrl = dirtyProject.attributes.cover_url ?? null;
        project.sections = 'none';
        // console.log('project', project);
        resolve(project);

        // console.warn('TODO: rewrite the cleanProject to prevent the getProjectBoat()');
        // if (project.boatId) {
        //   this.projectService.getProjectBoat(project.id).then((call$) => {
        //     call$.subscribe(async (retrievedBoat) => {
        //       const boat: Boat = await this.cleanBoat(retrievedBoat);
        //       project.name = boat.name;
        //       project.boatImageId = (boat.imageId && (boat.imageId !== null) && (boat.imageId !== 'null')) ? boat.imageId : null;
        //       project.coverImageUrl = dirtyProject.attributes?.cover_url ?? null;
        //       project.sections = boat.sections;
        //       console.log('project', project);
        //       resolve(project);
        //     });
        //   });
        // }
        // else {
        //   resolve(project);
        // }
      } catch (error) {
        reject(error);
      }
    });
  }

  private cleanBoat(dirtyBoat: any) {
    return new Promise<Boat>(async (resolve, reject) => {
      try {
        const boat: Boat = new Boat();
        boat.id = '' + dirtyBoat.id;
        boat.name = dirtyBoat.attributes.name;
        boat.imageId = '' + dirtyBoat.meta.image;
        boat.sections = '' + dirtyBoat.attributes.project?.sections;
        resolve(boat);
      } catch (error) {
        reject(error);
      }
    });
  }

  private cleanSection(dirtySection: any, projectId: string) {
    return new Promise<Section>(async (resolve, reject) => {
      try {
        const section: Section = new Section();
        section.id = '' + dirtySection.id;
        section.name = dirtySection.attributes.name;
        section.projectId = projectId;
        section.boatId = '' + dirtySection.attributes.boat_id;
        section.imageId = '' + dirtySection.meta.image;
        section.position = dirtySection.attributes.position;
        resolve(section);
      } catch (error) {
        reject(error);
      }
    });
  }

  private cleanTask(dirtyTask: any, projectId: string, sectionId: string, sectionName: string) {
    return new Promise<Task>(async (resolve, reject) => {
      try {
        const task: Task = new Task();
        task.id = '' + dirtyTask.id;
        task.name = dirtyTask.attributes.name;
        task.status = dirtyTask.attributes.status;
        task.x = dirtyTask.attributes.x_coord;
        task.y = dirtyTask.attributes.y_coord;
        task.projectId = (projectId) ? projectId : ('' + dirtyTask.attributes.project_id);
        task.sectionId = (sectionId) ? sectionId : ('' + dirtyTask.attributes.section_id);
        task.sectionName = (sectionName) ? sectionName : ('' + dirtyTask.attributes.section_name);
        task.internalProgressiveNumber = dirtyTask.attributes.internal_progressive_number || 0;

        task.resolutionDate = dirtyTask.attributes.resolution_date || null;
        task.dreamDocument = dirtyTask.attributes.dream_document || null;
        task.userInCharge = dirtyTask.attributes.user_in_charge || null;

        task.author = '' + dirtyTask.attributes.author;
        task.authorId = '' + dirtyTask.attributes.author_id;
        task.authorInitials = Utilities.getInitials(task.author);
        task.createdAt = '' + dirtyTask.attributes['created-at'];
        task.createdAtText = task.createdAt;
        task.updatedAt = '' + dirtyTask.attributes['updated-at'];
        task.updatedAtText = task.updatedAt;
        task.description = '' + dirtyTask.attributes.description;
        task.ipn = '' + dirtyTask.attributes.internal_progressive_number;
        task.interventTypeId = '' + dirtyTask.attributes.intervent_type_id;
        // task.interventTypeName = '' + dirtyTask.attributes.intervent_type_name;
        // eslint-disable-next-line max-len
        task.interventTypeName = '' + dirtyTask.attributes.intervent_type_name?.charAt(0).toUpperCase() + dirtyTask.attributes.intervent_type_name?.slice(1);
        task.isOpen = dirtyTask.attributes.is_open;
        task.isPrivate = dirtyTask.attributes.is_private;
        task.lastEditor = '' + dirtyTask.attributes.last_editor;
        task.lastEditorId = '' + dirtyTask.attributes.last_editor_id;
        task.lastEditorInitials = Utilities.getInitials(task.lastEditor, 1, 2);

        // const lastHistory = (dirtyTask.attributes.last_history) ? dirtyTask.attributes.last_history.attributes : null;
        // lastHistory.id = (dirtyTask.attributes.last_history) ? dirtyTask.attributes.last_history.id : null;
        const lastHistory = await this.cleanTaskHistory(dirtyTask.attributes.last_history);
        task.lastHistory = lastHistory;

        const history = [];
        await Utilities.asyncForEach(dirtyTask.history, async (dirtyHistory: any) => {
          const cleanedHistory = dirtyHistory.attributes;
          history.push(cleanedHistory);
        });
        task.history = history;

        task.subsectionId = '' + dirtyTask.attributes.subsection_id;
        task.type = '' + dirtyTask.attributes.task_type;
        task.zone = '' + dirtyTask.attributes.zone;
        task.openerApplicationLogId = '' + dirtyTask.attributes.opener_application_log_id;
        task.openerApplicationLogName = '' + dirtyTask.attributes.opener_application_log_name;
        task.closerApplicationLogId = '' + dirtyTask.attributes.closer_application_log_id;
        task.closerApplicationLogName = '' + dirtyTask.attributes.closer_application_log_name;

        // added_by_storm: 0
        // comments: []
        // estimated_hours: 0
        // number: 0
        // worked_hours: 0

        resolve(task);
      } catch (error) {
        reject(error);
      }
    });
  }

  private cleanTaskHistory(dirtyTaskHistory: any) {
    return new Promise<TaskHistory>(async (resolve, reject) => {
      try {
        const taskHistory: TaskHistory = new TaskHistory();
        taskHistory.id = (dirtyTaskHistory) ? dirtyTaskHistory.id : null;
        taskHistory.comments = (dirtyTaskHistory && dirtyTaskHistory.attributes) ? dirtyTaskHistory.attributes.comments : null;
        taskHistory.photos = (dirtyTaskHistory && dirtyTaskHistory.attributes) ? dirtyTaskHistory.attributes.photos : null;
        taskHistory.documents = (dirtyTaskHistory && dirtyTaskHistory.attributes) ? dirtyTaskHistory.attributes.documents : null;
        resolve(taskHistory);
      } catch (error) {
        reject(error);
      }
    });
  }

  private prepareTaskAttributes(task: Task) {
    return new Promise<any>(async (resolve, reject) => {
      try {
        const dataAttributes: any = {};
        if (task) {
          if (task.status) {
            dataAttributes.status = task.status;
          }
          if (task.isOpen) {
            dataAttributes.is_open = task.isOpen;
          }

          if (task.x) {
            dataAttributes.x_coord = task.x;
          }

          if (task.y) {
            dataAttributes.y_coord = task.y;
          }

          if (task.projectId) {
            dataAttributes.project_id = task.projectId;
          }

          if (task.sectionId) {
            dataAttributes.section_id = task.sectionId;
          }

          if (task.interventTypeId) {
            dataAttributes.intervent_type_id = task.interventTypeId;
          }

          if (task.name) {
            dataAttributes.title = task.name;
          }

          if (task.description) {
            dataAttributes.description = task.description;
          }

          if (task.taskNumber) {
            // eslint-disable-next-line @typescript-eslint/dot-notation
            dataAttributes['number'] = task.taskNumber;
          }
          else {
            // eslint-disable-next-line @typescript-eslint/dot-notation
            dataAttributes['number'] = 0;
          }

          if (task.workedHours) {
            dataAttributes.worked_hours = task.workedHours;
          }
          else {
            dataAttributes.worked_hours = 0;
          }

          if (task.estimatedHours) {
            dataAttributes.estimated_hours = task.estimatedHours;
          }
          else {
            dataAttributes.estimated_hours = 0;
          }

          // "task_type": (this.task.task_type) ? this.task.task_type : 'primary',
          //   "title": this.task.description,
          //     "description": (this.task.description) ? this.task.description : '',
          //       "number": 0,
          //         "worked_hours": 0,
          //           "estimated_hours": 0,


          // task.id = '' + dirtyTask.id;
          // task.name = dirtyTask.attributes.name;
          // task.status = dirtyTask.attributes.status;
          // task.x = dirtyTask.attributes.x_coord;
          // task.y = dirtyTask.attributes.y_coord;
          // task.projectId = (projectId) ? projectId : ('' + dirtyTask.attributes.project_id);
          // task.sectionId = (sectionId) ? sectionId : ('' + dirtyTask.attributes.section_id);
          // task.sectionName = (sectionName) ? sectionName : ('' + dirtyTask.attributes.section_name);
          // task.internalProgressiveNumber = dirtyTask.attributes.internal_progressive_number || 0;

          // task.author = '' + dirtyTask.attributes.author;
          // task.authorId = '' + dirtyTask.attributes.author_id;
          // task.authorInitials = Utilities.getInitials(task.author);
          // task.createdAt = '' + dirtyTask.attributes['created-at'];
          // task.createdAtText = task.createdAt;
          // task.updatedAt = '' + dirtyTask.attributes['updated-at'];
          // task.updatedAtText = task.updatedAt;
          // task.description = '' + dirtyTask.attributes.description;
          // task.ipn = '' + dirtyTask.attributes.internal_progressive_number;
          // task.interventTypeId = '' + dirtyTask.attributes.intervent_type_id;
          // task.interventTypeName = '' + dirtyTask.attributes.intervent_type_name;
          // task.isOpen = dirtyTask.attributes.is_open;
          // task.isPrivate = dirtyTask.attributes.is_private;
          // task.lastEditor = '' + dirtyTask.attributes.last_editor;
          // task.lastEditorId = '' + dirtyTask.attributes.last_editor_id;
          // task.lastEditorInitials = Utilities.getInitials(task.lastEditor);
          // const lastHistory = (dirtyTask.attributes.last_history) ? dirtyTask.attributes.last_history.attributes : null;
          // lastHistory.id = (dirtyTask.attributes.last_history) ? dirtyTask.attributes.last_history.id : null;
          // task.lastHistory = lastHistory;
          // task.subsectionId = '' + dirtyTask.attributes.subsection_id;
          // task.type = '' + dirtyTask.attributes.task_type;
          // task.zone = '' + dirtyTask.attributes.zone;
          // task.openerApplicationLogId = '' + dirtyTask.attributes.opener_application_log_id;
          // task.openerApplicationLogName = '' + dirtyTask.attributes.opener_application_log_name;
          // task.closerApplicationLogId = '' + dirtyTask.attributes.closer_application_log_id;
          // task.closerApplicationLogName = '' + dirtyTask.attributes.closer_application_log_name;

          // added_by_storm: 0
          // comments: []
          // estimated_hours: 0
          // number: 0
          // worked_hours: 0
        }

        resolve(dataAttributes);
      } catch (error) {
        reject(error);
      }
    });
  }
}
